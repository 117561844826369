<template>
 <div id="wrap">
  <section id="top clearfix">
   <div class="page-banner pic scroll-animate animated fadeInDownSmall" data-effect="fadeInDownSmall"><img src="../assets/images/page-banner.jpg" /></div>
   <div class="clear"></div>
   <div class="msg">
    <h1 class="f-50 mb-12">关于品牌</h1>
    <div id="down-more">
     <i class="wc"><img src="../assets/images/jt2.png" /></i>
     <br />
     <a class="">Scroll More</a>
    </div>
   </div>
   <section class="sub-menu clearfix">
    <div class="index-con">
     <div class="fl sub-menu-left">
      <em><img src="../assets/images/tu48.png" /></em>
      <router-link to="/">ABOUT</router-link>
      <em><img src="../assets/images/tu10.jpg" /></em>
      <router-link to="/about">关于品牌</router-link>
     </div>
    </div>
    <div class="clear"></div>
   </section>
  </section>
  <div class="about" style="padding-bottom: 0;">
   <div class="page-main">
    <div class="about">
     <div class="w1260">
      <h1>海顺科技（上海）有限公司</h1>
      <h2>Company profile</h2>
      <strong><i></i></strong>
      <figure>
       <p>
        海顺科技（上海）有限公司的工厂天津市万顺源机房设备有限公司成立于1992年，是专注于研发、生产、销售 “海顺”牌系列高架地板的生产企业，是ISO9001、 ISO14001、ISO18001的认证企业。工厂坐落于天津市滨 港高新铸造工业园区，205国道旁，距天津滨海国际机场79公里，距天津火车站60公里，距进出口港塘沽港80 公里，水、陆、空交通十分便利，地理位置优越。“海顺”牌高架地板与天津本地211大学紧密合作、展开自 主性研发、丰富的设计经验、完善的营销服务网络，受到国内外用户的普遍赞赏和推崇。
       </p>
      </figure>
     </div>
    </div>
    <div class="origin">
     <div class="origin-box">
      <div class="origin-content">
       <div class="origin-matter">
        <ul class="Guarantee_time  item selected clearfix">
         <li>
          <a href="">
           <h1 data-ride="numberGrow">28</h1>
           <p>二十八年的悠久历史</p>
          </a>
         </li>
         <li>
          <a href="">
           <div>
            <h1 data-ride="numberGrow">100</h1>
            <i>+</i>
           </div>
           <p>员工数</p>
          </a>
         </li>
         <li>
          <a href="">
           <div>
            <h1 data-ride="numberGrow">1000</h1>
            <i>+</i>
           </div>
           <p>1000+的经典案例</p>
          </a>
         </li>
         <li>
          <a href="">
           <div>
            <h1 data-ride="numberGrow">95</h1>
            <i>%</i>
           </div>
           <p>顾客满意度95%</p>
          </a>
         </li>
        </ul>
       </div>
      </div>
     </div>
     <div class="clear"></div>
    </div>

    <div class="abUs-comSR" id="shzr">
     <div class="w1300">
      <div class="p-title">
       <div class="t">企业文化</div>
       <i class="s"></i>
       <div class="c">企业文化是企业的灵魂，是推动企业发展的不竭动力。</div>
      </div>
      <ul class="auCSR-list clearfix">
       <li>
        <div class="auCSR-box">
         <div class="name"> 企业标语</div>
         <i class="icon i1"></i>
         <img class="auCSR-bg" src="../assets/images/20171113190920_51745.jpg" />
        </div>

        <div class="auCSR-con">
         <div class="auCSR-tb">
          <div class="auCSR-td">
           <div class="name"> 企业标语 </div>
           <div class="con">
            专业的高架地板服务商
           </div>
          </div>
         </div>
        </div>
       </li>

       <li>
        <div class="auCSR-box">
         <div class="name"> 经营理念</div>
         <i class="icon i2"></i>
         <img class="auCSR-bg" alt="" src="../assets/images/20171113190935_84300.jpg" />
        </div>
        <div class="auCSR-con">
         <div class="auCSR-tb">
          <div class="auCSR-td">
           <div class="name"> 经营理念</div>
           <div class="con">
              专业创新、精亦求精
           </div>
          </div>
         </div>
        </div>
       </li>

       <li>
        <div class="auCSR-box">
         <div class="name"> 核心价值观</div>
         <i class="icon i3"></i>
         <img class="auCSR-bg" alt="" src="../assets/images/20171113190945_25790.jpg" />
        </div>
        <div class="auCSR-con">
         <div class="auCSR-tb">
          <div class="auCSR-td">
           <div class="name">核心价值观</div>
           <div class="con">
               真诚  专业 高效 创新
           </div>
          </div>
         </div>
        </div>
       </li>

       <li>
        <div class="auCSR-box">
         <div class="name"> 团队精神</div>
         <i class="icon i4"></i>
         <img class="auCSR-bg" alt="" src="../assets/images/20171113190956_81746.jpg" />
        </div>
        <div class="auCSR-con">
         <div class="auCSR-tb">
          <div class="auCSR-td">
           <div class="name">团队精神</div>
           <div class="con">
           专业 专注 勇气 梦想
           </div>
          </div>
         </div>
        </div>
       </li>

       <li>
        <div class="auCSR-box">
         <div class="name"> 企业愿景</div>
         <i class="icon i5"></i>
         <img class="auCSR-bg" alt="" src="../assets/images/20171113191009_42681.jpg" />
        </div>
        <div class="auCSR-con">
         <div class="auCSR-tb">
          <div class="auCSR-td">
           <div class="name">企业愿景</div>
           <div class="con">
            合作共赢
           </div>
          </div>
         </div>
        </div>
       </li>
      </ul>
     </div>
    </div>

    <div class="honor">
     <div class="w1300 ">
      <div class="p-title">
       <div class="t">荣誉资质</div>
       <i class="s"></i>
       <div class="c">深耕技术研发，蓄势行业未来</div>
      </div>
      <div class="tplb">
       <ol class="clearfix">
        <li>
         <a href="javascript:void(0)">
          <div class="tyt">
           <em><img src="/images/about/zizhi2.png" /></em>
           <b></b>
           <!-- <span>查看详情</span> -->
          </div>
          <!-- <strong>荣誉资质</strong> -->
         </a>
        </li>
        <li>
         <a href="javascript:void(0)">
          <div class="tyt">
           <em><img src="/images/about/zizhi4.png" /></em>
           <b></b>
           <!-- <span>查看详情</span> -->
          </div>
          <!-- <strong>荣誉资质</strong> -->
         </a>
        </li>
        <li>
         <a href="javascript:void(0)">
          <div class="tyt">
           <em><img src="/images/about/zizhi3.png" /></em>
           <b></b>
           <!-- <span>查看详情</span> -->
          </div>
          <!-- <strong>荣誉资质</strong> -->
         </a>
        </li>
        
       </ol>
       <ol class="clearfix">
         <li>
          <a href="javascript:void(0)">
           <div class="tyt">
            <em><img src="/images/about/zizhi8.png" /></em>
            <b></b>
            <!-- <span>查看详情</span> -->
           </div>
           <!-- <strong>荣誉资质</strong> -->
          </a>
         </li>
         <li>
          <a href="javascript:void(0)">
           <div class="tyt">
            <em><img src="/images/about/zizhi6.png" /></em>
            <b></b>
            <!-- <span>查看详情</span> -->
           </div>
           <!-- <strong>荣誉资质</strong> -->
          </a>
         </li>
         <li>
          <a href="javascript:void(0)">
           <div class="tyt">
            <em><img src="/images/about/zizhi7.png" /></em>
            <b></b>
            <!-- <span>查看详情</span> -->
           </div>
           <!-- <strong>荣誉资质</strong> -->
          </a>
         </li>
        
       </ol>
      </div>
     </div>
    </div>
   </div>
  </div>  
  <indexfotter></indexfotter>
 </div>
</template>
<script>
import indexfotter from '../components/indexfotter.vue';
export default {
 name: 'Home',
 components: {
  indexfotter
 }
};
</script>
<style scoped>
  .tplb li{
   min-height: 320px;
  }
 .tplb img{
   width: 100%;
  }
  .tplb ol{
       text-align: center;
       display: inline-block;
  }
</style>