<template>
 <div id="wrap">
  <section id="top clearfix">
   <div class="page-banner pic scroll-animate animated fadeInDownSmall" data-effect="fadeInDownSmall"><img src="../assets/images/page-banner.jpg" /></div>
   <div class="clear"></div>
   <div class="msg">
    <h1 class="f-50 mb-12">联系我们</h1>
    <div id="down-more">
     <i class="wc"><img src="../assets/images/jt2.png" /></i>
     <br />
     <a class="">Scroll More</a>
    </div>
   </div>
   <section class="sub-menu clearfix">
    <div class="index-con">
     <div class="fl sub-menu-left">
      <em><img src="../assets/images/tu48.png" /></em>
      <router-link to="/">CONTACT</router-link>
      <em><img src="../assets/images/tu10.jpg" /></em>
      <router-link to="/SERVICE">联系我们</router-link>
     </div>
    </div>
    <div class="clear"></div>
   </section>
  </section>

  <div class="page-main">
   <div class="rc_box" style="text-align: center;">
    <div class="w1260">
     <div class="rcln_t"><img src="../assets/images/Contact-title.png" alt="" /></div>
     <div class="N_contact_son_text_L" style="width: 100%;max-width: 450px; margin: 0 auto; text-align: left;">
      <p>
       <strong>经营地址</strong>
       :上海市浦东新区双桥路1255号
      </p>
      <p>
       <strong>工厂地址</strong>
       :天津市静海区滨港高新铸造工业区滨港三大道
      </p>
      <p>
       <strong>公司电话</strong>
       ：021-51683436
      </p>
     <p>
       <strong>联系人员</strong>
       : 宋漪

      </p>
      <p>
       <strong>联系电话</strong>
       ：13816501488
      </p>
    <!--  <p>
       <strong>官方网址</strong>
       ：http://www.tjwanshun.com
      </p> -->
     </div>
<!--     <div class="fl code cwx">
      <a href=""><img src="images/erweima.jpg" /></a>
      <span>关注微信号</span>
     </div>
     <div class="fl code cwb">
      <a href=""><img src="images/erweima.jpg" /></a>
      <span>关注微博号</span>
     </div> -->
    </div>
   </div>
  </div>
  <indexfotter></indexfotter>
  <div class="clear"></div>
 </div>
</template>
<script>
import indexfotter from '../components/indexfotter.vue';
export default {
 name: 'Home',
 components: {
  indexfotter
 }
};
</script>

<style></style>
