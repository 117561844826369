<template>
 <div>
  <div class="menu clearfix">
   <ul class="pinner fr">
    <el-menu :default-active="active" class="el-menu-demo" mode="horizontal" @select="toggleActive">
     <el-menu-item index="1">铝合金高架地板</el-menu-item>
     <el-menu-item index="2">钢制高架地板</el-menu-item>
    </el-menu>
   </ul>
   <div class="page-main itempage" v-if="active === '1'">
    <div class="item-div1 item-div">
     <ul>
      <li>
       <a href="Javascript:void(0)">
        <figure><img src="/images/cases/3.jpg" /></figure>
        <figcaption><h3>高端半导体厂房</h3></figcaption>
       </a>
      </li>
      <li>
       <a href="Javascript:void(0)">
        <figure><img src="/images/cases/1.jpg" /></figure>
        <figcaption><h3>洁净室</h3></figcaption>
       </a>
      </li>
      <li></li>
     </ul>
    </div>
   </div>
   <div class="page-main itempage" v-if="active === '2'">
    <div class="item-div1 item-div">
     <ul>
      <li>
       <a href="Javascript:void(0)">
        <figure><img src="/images/cases/2.jpg" /></figure>
        <figcaption><h3>数据中心及机房</h3></figcaption>
       </a>
      </li>
      <li>
       <a href="Javascript:void(0)">
        <figure><img src="/images/cases/al13.png" /></figure>
        <figcaption><h3>高档办公楼</h3></figcaption>
       </a>
      </li>
     <!-- <li>
       <a href="Javascript:void(0)">
        <figure><img src="/images/cases/case2.jpg" /></figure>
        <figcaption><h3>洁净室及高科技厂房</h3></figcaption>
       </a>
      </li> -->
      <li></li>
     </ul>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 data() {
  return {
   active: '1'
  };
 },
 methods: {
  toggleActive(value) {
   this.active = value;
  }
 }
};
</script>

<style scoped>
.menu {
 padding: 0 60px;
}
.item-div1 figure {
 height: 250px;
 overflow: hidden;
}
.item-div1 figure img {
 height: 100%;
 width: auto;
 background: white;
}
</style>
