<template>
 <div id="wrap">
  <section id="top clearfix">
   <div class="page-banner pic scroll-animate animated fadeInDownSmall" data-effect="fadeInDownSmall"><img src="../assets/images/page-banner.jpg" /></div>
   <div class="clear"></div>
   <div class="msg">
    <h1 class="f-50 mb-12">环境 与 制造</h1>
    <div id="down-more">
     <i class="wc"><img src="../assets/images/jt2.png" /></i>
     <br />
     <a class="">Scroll More</a>
    </div>
   </div>
   <section class="sub-menu clearfix">
    <div class="index-con">
     <div class="fl sub-menu-left">
      <em><img src="../assets/images/tu48.png" /></em>
      <router-link to="/">BUSINESS</router-link>
      <em><img src="../assets/images/tu10.jpg" /></em>
      <router-link to="/business">环境 与 制造</router-link>
     </div>
     <ul class="pinner fr">
      <li @click="active = 0"><a href="javascript:void(0)" class="link " :class="{ on: active === 0 }">铝制地板生产</a></li>
      <li @click="active = 1"><a href="javascript:void(1)" class="link" :class="{ on: active === 1 }">钢制地板生产</a></li>
    <!--     <li @click="active = 2"><a href="javascript:void(2)" class="link" :class="{ on: active === 2 }">制造</a></li>
      <li @click="active = 3"><a href="javascript:void(3)" class="link" :class="{ on: active === 3 }">安装</a></li>
      <li @click="active = 4"><a href="javascript:void(3)" class="link" :class="{ on: active === 4 }">规格与质量</a></li> -->
     </ul>
    </div>
    <div class="clear"></div>
   </section>
  </section>

  <div class="page-main itempage" v-if="active === 0">
   <div class="p-title">
    <div class="t">厂房</div>
    <p>厂房占地面积 45亩；生产车间面积</p>
    <p>一车间5500m2 、二车间6500m2</p>
    <i class="s"></i>
   </div>
   <div class="item-div2 item-div"><img style="width: 100%;" src="/images/business/cf2.png" /></div>
   <div class="p-title">
    <div class="t">设备</div>
    <i class="s"></i>
   </div>
   <div class="item-div2 item-div">
    <img style="width: 100%;margin: 0 auto;" src="/images/business/shebei.jpg" />
   </div>
  </div>
  
  <div class="page-main itempage" v-if="active === 1">
   <div class="p-title">
    <div class="t">厂房</div>
    <p>厂房占地面积 100亩；生产车间面积30000㎡ </p>
    <i class="s"></i>
   </div>
   <div class="item-div2 item-div"><img style="width: 100%;" src="/images/business/cf.png" /></div>
   <div class="p-title">
    <div class="t">设备</div>
    <i class="s"></i>
   </div>
   <div class="item-div2 item-div">
    <img style="width: 100%;margin: 0 auto;" src="/images/business/shebei2.jpg" />
   </div>
  </div>
  <indexfotter></indexfotter>
 </div>
</template>

<script>
import indexfotter from '../components/indexfotter.vue';
export default {
 name: 'Home',
 components: {
  indexfotter
 },
 data() {
  return {
   active: 0
  };
 }
};
</script>

<style scoped>
.item-div1 figure {
 height: 250px;
 overflow: hidden;
}
.item-div1 figure img {
 height: 100%;
 width: auto;
 background: white;
}
</style>
