<template>
 <div>
  <div class="menu clearfix">
   <ul class="pinner fr">
    <!-- <li  v-for="(item,index) in products" :key='index'><a class="link " :class="{'on':index===active}" @click="toggleActive(index)">{{item.name}}</a></li> -->
    <el-menu :default-active="active" class="el-menu-demo" mode="horizontal" @select="toggleActive">
     <el-menu-item :index="item.name" v-for="(item, index) in products" :key="index">{{ item.name }}</el-menu-item>
    </el-menu>
   </ul>
  </div>
  <div v-for="(item, index) in products" v-show="item.name === active">
   <div class="linian">
    <div class="index-con">
     <div class="p-title">
      <div class="t">{{ item.name }}</div>
     </div>
     <ol class="mt50">
      <li v-for="product in item.children" :key="product.name">
       <a @click="godetail(product.id)">
        <div class="cont index-table" :class="{ fl: product.showType === 'left', fr: product.showType === 'right' }">
         <div class="index-cell">
          <h1>{{ product.name }}</h1>
          <!-- <p>Intelligent Face Recognition System</p> -->
          <span></span>
          <figure>
           <p>{{ product.desc }}</p>
           <p>特点:{{ product.special }}</p>
          </figure>
         </div>
        </div>
        <div class="ln_img" :class="{ fl: product.showType === 'left', fr: product.showType === 'right' }"><img :src="product.imgUrl" /></div>
       </a>
      </li>
     </ol>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
 import axios from 'axios'
export default {
 data() {
  return {
   active: '',
   products:[]
  };
 },
 async mounted() {
   const res =await axios.get('/data/products.json')
   this.products = res.data
  this.active= this.products[0].name
 },
 methods: {
  toggleActive(value) {
   this.active = value;
  },
  godetail(id) {
   this.$router.push({
    path: '/detail',
    query: {
     id
    }
   });
  }
 }
};
</script>

<style scoped>
 .menu{
  padding: 0 60px;
 }
</style>
