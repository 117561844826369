<template>
 <div id="wrap">
 	<section id="top clearfix">
 		<div class="page-banner pic scroll-animate animated fadeInDownSmall" data-effect="fadeInDownSmall">
 			<img src="../assets/images/page-banner.jpg">
 		</div>
 		<div class="clear"></div>
 		<div class="msg">
 			<h1 class="f-50 mb-12">经典案例</h1>
 			<div id="down-more">
 				<i class="wc"><img src="../assets/images/jt2.png"></i><br>
 				<a class="">Scroll More</a>
 			</div>
 		</div>
 		<section class="sub-menu clearfix">
 			<div class="index-con">
 				<div class="fl sub-menu-left">
 					<em><img src="../assets/images/tu48.png"></em>
      <router-link to="/">CASES</router-link>
      <em><img src="../assets/images/tu10.jpg" /></em>
      <router-link to="/SERVICE">经典案例</router-link>
 				</div>
      <ul class="pinner fr">
       <li @click="active = 0"><a href="javascript:void(0)" class="link " :class="{ on: active === 0 }"> 铝合金地板案例</a></li>
       <li @click="active = 1"><a href="javascript:void(1)" class="link" :class="{ on: active === 1 }">钢制高架地板案例</a></li>
 				</ul>
 			</div>
 			<div class="clear"></div>
 		</section>
 	</section>
 
 	<div class="page-main itempage" v-if="active===1">
 		<div class="p-title">
 			<div class="t">知名工程案例</div>
 			<i class="s"></i>
 		</div>
 		<div class="item-div1 item-div">
 			<ul>
 				<li v-for="(item,index) in steelList">
 					<a href="Javascript:void(0)">
 						<figure>
 							<img :src="item.img" />
 						</figure>
 						<figcaption>
 							<h3>{{item.name}}</h3>
 						</figcaption>
 					</a>
 				</li>
 			</ul>
 		</div>
<!--    <div class="p-title">
    	<div class="t">更多案例</div>
    	<i class="s"></i>
    </div>
   <div class="item-div2 item-div" >
   	 <img style="width: 80%;margin: 0 auto;" src="/images/cases/cases10.png" />
   </div> -->
 
 	</div>
 <div class="page-main itempage" v-if="active===0">
  <template v-for="(item,index) in caseAlloyList">
  <div class="p-title" :key='index'>
  	<div class="t">{{item.name}}</div>
  	<i class="s"></i>
  </div>
  <div class="item-div2 item-div" >
  	 <img style="width: 100%;" :src="item.img" />
  </div>
 </template>
 </div>
 <indexfotter></indexfotter>
 </div>
</template>

<script>
import indexfotter from '../components/indexfotter.vue';
import axios from 'axios'
export default {
 name: 'Home',
 components: {
  indexfotter
 },
 data() {
  return {
   active: 0,
   caseAlloyList:[],
   steelList:[]
  };

 },
 async mounted() {
   const res =await axios.get('./data/cases-alloy.json')
   this.caseAlloyList= res.data
   const res2 =await axios.get('./data/cases-steel.json')
   this.steelList = res2.data
   }
};
</script>
<style scoped>
 .item-div1 figure{
  height: 250px;
  overflow: hidden;
 }
 .item-div1 figure img{
  height: 100%;
  width: auto;
  background: white;
 }
 .item-div{
  padding: 30px 0 40px 0;
 }
</style>
