<template>
 <div id="wrap">
  <section id="top clearfix">
   <div class="page-banner pic scroll-animate animated fadeInDownSmall" data-effect="fadeInDownSmall"><img src="../assets/images/page-banner.jpg" /></div>
   <div class="clear"></div>
   <div class="msg">
    <h1 class="f-50 mb-12">技术支持</h1>
    <div id="down-more">
     <i class="wc"><img src="../assets/images/jt2.png" /></i>
     <br />
     <a class="">Scroll More</a>
    </div>
   </div>
   <section class="sub-menu clearfix">
    <div class="index-con">
     <div class="fl sub-menu-left">
      <em><img src="../assets/images/tu48.png" /></em>
      <router-link to="/">SERVICE</router-link>
      <em><img src="../assets/images/tu10.jpg" /></em>
      <router-link to="/SERVICE">服务与支持</router-link>
     </div>
    </div>
    <ul class="pinner fr">
     <li @click="active = 0"><a href="javascript:void(0)" class="link " :class="{ on: active === 0 }">服务与支持</a></li>
     <li @click="active = 1"><a href="javascript:void(1)" class="link" :class="{ on: active === 1 }">我们的伙伴</a></li>
    </ul>
    <div class="clear"></div>
   </section>
  </section>

  <div class="page-main" v-if="active === 0">
   <div class="linian service">
    <div class="w1300">
     <div class="p-title">
      <div class="t">服务与支持</div>
      <i class="s"></i>
      <div class="c" style="font-family: arial; text-transform: uppercase;">Service and support</div>
     </div>
     <ol class="mt50">
      <li>
       <a href="javascript:void(0)">
        <div class="ln_img fl"><img src="../assets/images/fw-pic1.jpg" /></div>
        <div class="cont index-table fr">
         <div class="index-cell">
          <h1>产品质保</h1>
          <figure style="text-align: left;">
           <p>1. 工程质量和所用产品质量均符合国家检验 检测标准和当地相关部门技术要求；</p>
           <p>2. 施工工程质量及产品质保2年，2年内对产 品进行免费维修保养，请用户放心使用；</p>
           <p>3. 施工工程满2年免费维修保养期后，按市 场行情签定维修保养协议书，继续维修保 养；</p>
           <p>4. 我们对所售产品提供24小时售后服务，客 户提出问题后，我们将在24小时内作出回 应， 并解决问题。</p>
          </figure>
         </div>
        </div>
       </a>
      </li>
      <li>
       <a href="javascript:void(0)">
        <div class="ln_img fr"><img src="../assets/images/pic4.1.jpg" /></div>
        <div class="cont index-table fr">
         <div class="index-cell">
          <h1>日常维护与保养</h1>
          <figure style="text-align: left;">
           <p>1. 环境温度应控制在15度-35度,湿度控制在 45％-75％RH；</p>
           <p>2. 不要使用锐利的工具在地板的贴面上进行 任何的施工操作；</p>
           <p>3. 严格禁止超过地板称重的物品放置在地板 上；</p>
           <p>4. 经常保持室内的空气流通；</p>
           <p>5. 在地板使用过程中，一定要注意防水；</p>
           <p>6. 定期对地板进行打蜡等保养操作，保持防 静电地板的良好状态。</p>
          </figure>
         </div>
        </div>
       </a>
      </li>
      <!-- <li>
       <a href="javascript:void(0)">
        <div class="ln_img fl"><img src="/images/service.jpg" /></div>
        <div class="cont index-table fr">
         <div class="index-cell">
          <h1>施工规范</h1>
          <figure style="text-align: left;">
           <p>1. 清洁地面</p>
           <p>2. 画网格线</p>
           <p>3. 放置支架</p>
           <p>4. 调整水平</p>
           <p>5. 横梁连接</p>
           <p>6. 安装底板</p>
           <p>7. 地板封边</p>
           <p>8. 清洁地板</p>
          </figure>
         </div>
        </div>
       </a>
      </li> -->
     </ol>
     <div class="p-title">
      <div class="t">施工规范</div>
      <i class="s"></i>
     </div>
     <div class="item-div2 "><img style="width: 70%;margin: 0 auto;" src="/images/service.jpg" /></div>
     <div class="p-title">
      <div class="t">施工现场</div>
      <i class="s"></i>
     </div>
     <div class="item-div2 "><img style="width: 70%;margin: 0 auto;" src="images/cases/sn.jpg" /></div>
     <div class="p-title">
      <div class="t">完工现场</div>
      <i class="s"></i>
     </div>
     <div class="item-div2 "><img style="width: 70%;margin: 0 auto;" src="/images/cases/cases11.png" /></div>
    </div>
   </div>
  </div>
  <div class="page-main" v-if="active === 1">
   <div class="linian service">
    <div class="list-company">
     <div class="p-title">
      <div class="t">我们的伙伴</div>
      <i class="s"></i>
     </div>
     <ul>
      <li>
       <p>昆山宏儒</p>
       <p>无锡晋楚</p>
       <p>中铁四局</p>
       <p>厦门闽工</p>
       <p>苏州泰邦</p>
       <p>昆山稳钻</p>
       <p>中电二</p>
       <p>中电三</p>
       <p>中电四</p>
       <p>昆山英建</p>
      </li>
      <li>
       <p>昆山讯能</p>
       <p>美克威尔</p>
       <p>昆山富罡</p>
       <p>中净丰泰</p>
       <p>江苏启安</p>
       <p>无锡柏诚</p>
       <p>江苏智塔</p>
       <p>南通安装</p>
       <p>苏州辰康</p>
       <p>昆山华鑫</p>
      </li>

      <li>
       <p>苏州良晟</p>
       <p>苏州定捷</p>
       <p>江苏仲棠</p>
       <p>江苏中洁信</p>
       <p>江苏中电创达</p>
       <p>东莞旭峰</p>
       <p>上海顼晖</p>
       <p>江苏苏净</p>
       <p>苏州睿捷昊</p>
      </li>
     </ul>
    </div>
   </div>
  </div>
  <indexfotter></indexfotter>
  <div class="clear"></div>
 </div>
</template>
<script>
import indexfotter from '../components/indexfotter.vue';
export default {
 name: 'Home',
 components: {
  indexfotter
 },
 data() {
  return {
   active: 0
  };
 }
};
</script>

<style></style>
