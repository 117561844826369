<template>
 <div id="wrap">
  <section id="top clearfix">
   <div class="page-banner pic scroll-animate animated fadeInDownSmall" data-effect="fadeInDownSmall"><img src="../assets/images/page-banner.jpg" /></div>
   <div class="clear"></div>
   <div class="msg">
    <h1 class="f-50 mb-12">产品中心</h1>
    <div id="down-more">
     <i class="wc"><img src="../assets/images/jt2.png" /></i>
     <br />
     <a class="">Scroll More</a>
    </div>
   </div>
   <section class="sub-menu clearfix">
    <div class="index-con">
     <div class="fl sub-menu-left">
      <em><img src="../assets/images/tu48.png" /></em>
      <router-link to="/">PRODUCT</router-link>
      <em><img src="../assets/images/tu10.jpg" /></em>
      <router-link to="/products">产品中心</router-link>
     </div>
    </div>
    <div class="clear"></div>
   </section>
  </section>

  <div class="page-main">
   <div class="index-con">
    <div class="p-title">
     <div class="t">{{ product.name }}</div>
    </div>
    <div class="index-con2">
     <div class="c">{{ product.desc }}</div>
     <div class="detail-special">
      <p>产品特点</p>
      <p>{{ product.special }}</p>
     </div>
    <!-- <div class="detail-table" v-if="product.params">
      <table class="detail-table el-table ">
       <thead>
        <tr><td>名称</td><td>规格(mm)</td><td>集中载荷</td><td>均布载荷N/m2</td><td>极限载荷</td><td>防火性能</td></tr>
       </thead>
       <tbody>
        <tr v-for="item in product.params" :key='item.name'>
         <td>{{item.name}}</td>
         <td>
          <p v-for="specs in item.specs" :key='specs'>{{specs}}</p>
         </td>
         <td>{{item.SLoad}}</td>
         <td>{{item.DLoad}}</td>
         <td>{{item.MLoad}}</td>
         <td>{{item.fireproof}}</td>
        </tr>
       </tbody>
      </table>
     </div> -->
    <div class="imglist" v-if="product.paramsUrl"><img :src="product.paramsUrl"/></div>
     <div class="imglist">
      <img :src="product.imgUrl" />
     </div>
    </div>
   </div>
  </div>
  <indexfotter></indexfotter>
 </div>
</template>
    <!-- ["名称","规格(mm)","集中载荷","均布载荷N/m2","极限载荷","防火性能"] -->
<script>
 import axios from 'axios'
import indexfotter from '../components/indexfotter.vue';
export default {
 components:{
  indexfotter
 },
 data() {
  return {
   product: {},
   products:[]
  };
 },
 async mounted() {
   const res =await axios.get('/data/products.json')
   this.products = res.data
  let id = this.$route.query.id;
   this.products.forEach(x => {
   x.children.forEach(item => {
    if (item.id === id) {
     this.product = item;
    }
   });
  });
 },
 methods: {}
};
</script>

<style scoped>
.p-title {
 border-bottom: 1px solid #dedede;
 padding-top: 60px;
 padding-bottom: 20px;
 
}
.c{
 max-width: 700px;
 margin: 0 auto;
}
.index-con2{
 padding: 30px  100px;
}
.detail-special{
 text-align: left;
 max-width: 700px;
 margin: 0 auto;
 padding: 20px 100px;
}
.detail-table{
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}
.imglist{
   padding: 50px 0 ;
   img{
    width: 400px;
   }
}
</style>
