<template>
 <div id="wrap">
  <section id="top clearfix">
   <div class="page-banner pic scroll-animate animated fadeInDownSmall" data-effect="fadeInDownSmall"><img src="../../assets/images/page-banner.jpg" /></div>
   <div class="clear"></div>
   <div class="msg">
    <h1 class="f-50 mb-12">产品中心</h1>
    <div id="down-more">
     <i class="wc"><img src="../../assets/images/jt2.png" /></i>
     <br />
     <a class="">Scroll More</a>
    </div>
   </div>
   <section class="sub-menu clearfix">
    <div class="index-con">
     <div class="fl sub-menu-left">
      <em><img src="../../assets/images/tu48.png" /></em>
      <router-link to="/">PRODUCT</router-link>
      <em><img src="../../assets/images/tu10.jpg" /></em>
      <router-link to="/products">产品中心</router-link>
     </div>
     <ul class="pinner fr">
      <li  v-for="(item,index) in products" :key='index'><a class="link " :class="{'on':item.id===active}" @click="toggleActive(item)">{{item.name}}</a></li>
     </ul>
    </div>
    <div class="clear"></div>
   </section>
  </section>
  <router-view></router-view>
  <indexfotter></indexfotter>
 </div>
</template>

<script>
 import indexfotter from '../../components/indexfotter.vue';
 export default{
  components: {
   indexfotter
  },
  data() {
   return {
    active:0,
    products:[{
     id:1,
     name:'铝合金高架地板系列',
     path:'/products/alloy'
    },{
     id:2,
     name:'钢制高架地板系列',
     path:'/products/steel'
    },{
     id:3,
     name:'进口高架地板',
     path:'/products/overhead'
    },{
     id:4,
     name:'应用领域',
     path:'/products/area'
    }]
   }
  },
  mounted() {
   this.active=this.products[0].id
  },
  methods:{
   toggleActive(item){
    if(this.active===item.id)return
    this.active=item.id
    this.$router.push({
     path:item?.path
    })
   }
  }
 }
</script>

<style></style>
