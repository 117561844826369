<template>
 <div>
  <div class="menu clearfix">
   <ul class="pinner fr">
    <!-- <li  v-for="(item,index) in products" :key='index'><a class="link " :class="{'on':index===active}" @click="toggleActive(index)">{{item.name}}</a></li> -->
    <el-menu :default-active="active" class="el-menu-demo" mode="horizontal" @select="toggleActive">
     <el-menu-item :index="item.name" v-for="(item, index) in products" :key="index">{{ item.name }}</el-menu-item>
    </el-menu>
   </ul>
  </div>
  <div class="page-main" v-for="(product, index) in products" :key="index" v-show="product.name === active">
    <div class="imglist" v-if="product.imgUrl"><img :src="product.imgUrl" /></div>
   <div class="index-con">
    <div class="index-con2">
     <div class="detail-special">
      <label>产品特点</label>
      <p v-for="(p,i) in product.special">{{i+1}}.{{ p }}</p>
     </div>
 <!--    <div class="detail-table" v-if="product.params">
      <table class="detail-table el-table ">
       <thead>
        <tr>
         <td>名称</td>
         <td>规格(mm)</td>
         <td>集中载荷</td>
         <td>均布载荷N/m2</td>
         <td>极限载荷</td>
         <td>防火性能</td>
         <td v-if="product.hasHole">开孔率</td>
        </tr>
       </thead>
       <tbody>
        <tr v-for="item in product.params" :key="item.name">
         <td>{{ item.name }}</td>
         <td>
          <p v-for="specs in item.specs" :key="specs">{{ specs }}</p>
         </td>
         <td>{{ item.SLoad }}</td>
         <td>{{ item.DLoad }}</td>
         <td>{{ item.MLoad }}</td>
         <td>{{ item.fireproof }}</td>
         <td v-if="product.hasHole">{{ item.hole }}</td>
        </tr>
       </tbody>
      </table>
     </div> -->
     <div class="imglist" v-if="product.paramsUrl"><img :src="product.paramsUrl"/></div>
    <div class="imglist" v-if="product.imgList"><img :src="item" v-for='item in product.imgList' :key='item'/></div>
    
    </div>
   </div>
  </div>
 </div>
</template>

<script>
// import products from '../../data/alloy';
import axios from 'axios'
export default {
 data() {
  return {
   active: '',
   products:[]
  };
 },
 async mounted() {
   const res =await axios.get('/data/alloy.json')
   this.products= res.data
  this.active = res.data[0].name;
 },
 methods: {
  toggleActive(value) {
   this.active = value;
  },
  godetail(id) {
   this.$router.push({
    path: '/detail',
    query: {
     id
    }
   });
  }
 }
};
</script>

<style scoped>
.menu {
 padding: 0 60px;
}
.p-title {
 border-bottom: 1px solid #dedede;
 padding-top: 60px;
 padding-bottom: 20px;
}
.index-con2 {
 padding: 30px 100px;
}
.detail-special {
 text-align: left;
 padding: 20px 100px;
 max-width: 700px;
 margin: 0 auto;
 font-size: 12px;
 color: #666;
}
.detail-special label{
 color: #141418;
 font-size: 16px;
 font-weight: blod;
}
.detail-table {
 max-width: 700px;
 margin: 0 auto;
 text-align: center;
}
.imglist {
 padding: 50px 80px 0 80px;
 
}
.imglist img {
  max-width: 800px;
 }
</style>
